import { useCallback, useEffect, useState } from "react";
import { addRecording, getRecentRecordings } from "@/utils/recentRecordingsDb";

interface RecentRecording {
  id?: number;
  title: string;
  blob: Blob;
  createdAt: number;
}

const useRecentRecordings = () => {
  const [recordings, setRecordings] = useState<RecentRecording[]>([]);

  const loadRecordings = useCallback(async () => {
    const recentRecordings = await getRecentRecordings();
    setRecordings(recentRecordings);
  }, []);

  const saveRecording = useCallback(async (chunks: Blob[]) => {
    if (chunks.length === 0) return;

    const previousSession = JSON.parse(localStorage.getItem("PREVIOUS_SESSION") || "{}");
    const recordingTitle = previousSession?.name || new Date().toISOString();

    const blob = new Blob(chunks, { type: "audio/ogg; codecs=opus" });

    await addRecording({ title: recordingTitle, blob, createdAt: Date.now() });

    await loadRecordings();
  }, [loadRecordings]);

  useEffect(() => {
    loadRecordings();
  }, [loadRecordings]);

  return { recordings, saveRecording, loadRecordings };
};

export default useRecentRecordings;
