import Dexie, { Table } from "dexie";
import { DATABASE_NAME } from "@/contants";

export interface RecentRecording {
  id?: number;
  title: string;
  blob: Blob;
  createdAt: number;
}

class RecentRecordingsDatabase extends Dexie {
  recordings!: Table<RecentRecording>;

  constructor() {
    super(`${DATABASE_NAME}_recent`);
    this.version(1).stores({
      recordings: "++id,createdAt",
    });
  }
}

const db = new RecentRecordingsDatabase();

export async function getRecentRecordings(): Promise<RecentRecording[]> {
  return await db.recordings.orderBy("createdAt").reverse().limit(3).toArray();
}

export async function addRecording(recording: Omit<RecentRecording, "id">): Promise<number> {
  const id = await db.recordings.add(recording);

  await clearOldRecordings();

  return id;
}

export async function clearOldRecordings() {
  const allRecordings = await db.recordings.orderBy("createdAt").toArray();
  if (allRecordings.length > 3) {
    const excess = allRecordings.length - 3;
    const oldIds = allRecordings.slice(0, excess).map((r) => r.id);
    await db.recordings.bulkDelete(oldIds as number[]);
  }
}

export async function clearAllRecordings() {
  await db.recordings.clear();
}

export default db;
